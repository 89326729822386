import axios from '@/axios'
import * as types from '../mutations_type'
import router from '@/router'

const state = {}
const actions = {
  // 指派列表
  actionUserBaList({ commit }, data) {
    axios
      .post(
        'api/user_project_code',
        {
          orderBy: data.orderBy,
          sortedBy: data.sortedBy,
        },
        {
          params: {
            page: data.page,
          },
        }
      )
      .then((res) => {
        console.log('指派資料:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },

  // 將下層指派給業務
  actionAddUserBa({ commit }, data) {
    axios
      .post('user_project_code/create', {
        orderBy: data.orderBy,
        sortedBy: data.sortedBy,
      })
      .then((res) => {
        console.log('指定BA給USER:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 維護業務擁有的下層BA
  actionEditBa({ commit }, data) {
    axios
      .path('api/project_code/store', {
        orderBy: data.orderBy,
        sortedBy: data.sortedBy,
      })
      .then((res) => {
        console.log('維護業務擁有的下層BA:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
}
const mutations = {}
const getters = {}
export default {
  state,
  actions,
  mutations,
  getters,
}
