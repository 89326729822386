import axios from '@/axios'
import * as types from '../mutations_type'
import router from '@/router'

const state = {}
const actions = {
  // 匯率列表
  actionExchangeRate({ commit }, data) {
    axios
      .post(
        'api/exchange_rates',
        {
          orderBy: data.orderBy,
          sortedBy: data.sortedBy,
        },
        {
          params: {
            page: data.page,
          },
        }
      )
      .then((res) => {
        console.log('匯率列表:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 匯率列表詳情
  actionUser({ commit }, data) {
    axios
      .post('exchange_rate')
      .then((res) => {
        console.log('匯率列表詳情:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 新增匯率
  actionAddER({ commit }, data) {
    axios
      .post('/exchange_rate/create', {
        orderBy: data.orderBy,
        sortedBy: data.sortedBy,
      })
      .then((res) => {
        console.log('新增匯率:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 編輯匯率
  actionEditER({ commit }, data) {
    axios
      .path('exchange_rate/update', {
        orderBy: data.orderBy,
        sortedBy: data.sortedBy,
      })
      .then((res) => {
        console.log('編輯匯率:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 刪除使用者
  actionDelUser({ commit }, data) {
    axios
      .delete('api/users/' + data.id)
      .then((res) => {
        console.log('刪除使用者:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
}
const mutations = {}
const getters = {}
export default {
  state,
  actions,
  mutations,
  getters,
}
