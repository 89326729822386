import Vue from 'vue'
import Vuex from 'vuex'

import Global from './modules/global'
import Login from './modules/login'
import Company from './modules/company'
import BankAccount from './modules/bankAccount'
import BillingAccount from './modules/billingAccount'
import ExchangeRate from './modules/exchangeRate'
import Profile from './modules/profile'
import Statement from './modules/statement'
import User from './modules/user'
import UserBa from './modules/userBa'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
}

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    Global,
    Login,
    Company,
    BankAccount,
    BillingAccount,
    ExchangeRate,
    Profile,
    Statement,
    User,
    UserBa,
  },
})
