import 'core-js/stable'
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import router from './router'

import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from '@/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
// Loading
import Loading from 'vue-loading-overlay' //component
import 'vue-loading-overlay/dist/vue-loading.css' //style
// npm install jquery --save
window.$ = window.jQuery = require('jquery')
// 引入 common.js共用
import common from './utils/common'
Vue.prototype.common = common
// vue-selsct
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
Vue.component('v-select', vSelect)

import lodash from 'lodash'
Vue.prototype.$lodash = lodash

import { ValidationProvider } from 'vee-validate'
import Validate from './utils/vee-validate'
Vue.use(Validate)
// dayjs
import dayjs from 'dayjs'
Vue.prototype.dayjs = dayjs
//啟用
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('Loading', Loading)

Vue.config.performance = true
Vue.use(VueAxios, axios)
Vue.use(Vuex)
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App,
  },
})
