import {
  extend,
  ValidationObserver,
  ValidationProvider,
  localize,
} from 'vee-validate'
import {
  required,
  email,
  integer,
  min,
  between,
  numeric,
} from 'vee-validate/dist/rules'
// import * as rules from 'vee-validate/dist/rules'
import zh_CN from 'vee-validate/dist/locale/zh_CN.json'
import TW from 'vee-validate/dist/locale/zh_TW.json'
localize('zh_cn', zh_CN) // 設定中文
localize('zh_TW', TW)
// 自訂義
extend('positive', {
  ...email,
  // 字符串占位符, 占位符内容由ValidationProvider组件name参数提供
  message: '帳號規格不符',
})
extend('confirmPassword', {
  params: ['password'],
  validate(value, { password }) {
    return value === password
  },
  message: '兩次密碼輸入不同，請重新確認',
})
// 內定
export default {
  install(Vue) {
    Vue.component('ValidationObserver', ValidationObserver)
    Vue.component('ValidationProvider', ValidationProvider)
    extend('required', { ...required, message: '{_field_}必填' })
    extend('email', { ...email, message: 'Email規格不正確' })
    extend('integer', { ...integer, message: '請輸入整數' })
    extend('min', min)
    extend('between', between)
    extend('numeric', numeric) //只允許數字

    extend('phone', {
      message: '請輸入11位的手機號碼',
      validate: (value) =>
        value.length === 11 && /^1[3456789]\d{9}$/.test(value),
    })
    // const regex =
    extend('price', {
      message: '價格格式不符,請輸入六位小數',
      validate: (value) => {
        return /^(\d+)\.(\d{6})$/.test(value)
      },
    })
    // extend('price', (value) => {
    //   if (value >= 0) {
    //     return true
    //   return '價格格式不符,請輸入六位小數'
    // })

    extend('username', { ...required, message: '帳號規格不符' })
  },
}
