import Global from '@/Global'
import * as types from '../mutations_type'

const state = {
  isLoading: false,
  api_request: {
    isVisible: false,
    code: '',
    data: '',
    msg: '',
    message: '',
    type: '',
  },
  modal_success: {
    isSuccess: false,
    code: '',
    data: '',
    msg: '',
    type: '',
  },
}

const actions = {}

const mutations = {
  //api request 共用彈窗資訊
  [types.SHOW_API_RES](state, data) {
    state.api_request.isVisible = true
    state.api_request.code = data.code
    state.api_request.msg = data.msg
    state.api_request.message = data.message
    state.api_request.type = data.type
  },
  // 關閉彈窗
  [types.HIDE_API_RES](state) {
    state.api_request.isVisible = false
  },
  //   成功彈窗
  [types.SHOW_MODAL_SUCCESS](state, data) {
    state.modal_success.isSuccess = true
    state.modal_success.code = data.code
    state.modal_success.msg = data.msg
    state.modal_success.message = data.message
    state.modal_success.type = data.type
  },
  [types.HIDE_MODAL_SUCCESS](state) {
    state.modal_success.isSuccess = false
  },
}

const getters = {
  getApi_request: (state) => state.api_request,
  getModal_success: (state) => state.modal_success,
}
export default {
  state,
  actions,
  mutations,
  getters,
}
