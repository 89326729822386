// 測試用
export const SETTOKEN = 'SETTOKEN'
export const DELTOKEN = 'DELTOKEN'

// 共用彈窗
export const SHOW_API_RES = 'SHOW_API_RES'
export const HIDE_API_RES = 'HIDE_API_RES'
export const SHOW_MODAL_SUCCESS = 'SHOW_MODAL_SUCCESS'
export const HIDE_MODAL_SUCCESS = 'HIDE_MODAL_SUCCESS'

// COMPANY
export const GET_COMPANIESALL = 'GET_COMPANIESALL'
export const GET_COMPANIES = 'GET_COMPANIES'
export const GET_COMPANIES_PAGE = 'GET_COMPANIES_PAGE'
export const GET_COMPANY = 'GET_COMPANY'
export const GET_COMPANYLOCATION = 'GET_COMPANYLOCATION'
export const GET_COMPANYCONTRACTS = 'GET_COMPANYCONTRACTS'
export const GET_COMPANYBANK = 'GET_COMPANYBANK'
export const GET_FUTUREBANK = 'GET_FUTUREBANK'

// PROFILE
export const GET_PROFILE = 'GET_PROFILE'
export const GET_PROFILEAUTH = 'GET_PROFILEAUTH'

// BANKACCOUNT
// export const GET_PROFILE = 'GET_PROFILE'

// BILLINGACCOUNT
// export const GET_PROFILE = 'GET_PROFILE'

// EXCHANGERATE
// export const GET_PROFILE = 'GET_PROFILE'

// STATEMENT
// export const GET_PROFILE = 'GET_PROFILE'

// USER
// export const GET_PROFILE = 'GET_PROFILE'

// USERBA
// export const GET_PROFILE = 'GET_PROFILE'
export const GET_INSTOCK = 'GET_INSTOCK'
