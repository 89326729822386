<template>
  <div>
    <loading :active.sync="isLoading"></loading>
    <router-view></router-view>
  </div>
</template>

<script>
import axios from '../src/axios'
import Global from './Global'
export default {
  name: 'App',
  data() {
    return {
      isLoading: false,
    }
  },

  mounted() {
    // http request 攔截器
    console.log('Loading')
    axios.interceptors.request.use(
      (config) => {
        console.log('config', config.url)
        if (config.url != 'api/languages' && config.url != 'api/currenies') {
          // 打開
          //   this.isLoading = true
        }

        return config
      },
      (err) => {
        //   關閉
        this.isLoading = false
        return Promise.reject(err)
      }
    )

    // http response 攔截器
    axios.interceptors.response.use(
      (response) => {
        // console.log('response', response)
        this.isLoading = false
        return response
      },
      (error) => {
        // if (error.response) {
        //   switch (error.response.status) {
        //     case 401:
        //       // 返回 401 清除token資訊並跳轉到登入頁面
        //       store.commit(types.DELTOKEN)
        //       router.replace({
        //         path: '/login',
        //         query: { redirect: router.currentRoute.fullPath },
        //       })
        //   }
        // }
        // this.isLoading = false
        return Promise.reject(error.response.data) // 返回介面返回的錯誤資訊
      }
    )
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>
