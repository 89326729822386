import Global from '@/Global'
import axios from '@/axios'
import * as types from '../mutations_type'

const state = {
  profile: '',
  profileAuth: '',
}

const actions = {
  // Profile
  actionGetProfile({ commit }) {
    axios
      .post('api/profile', {})
      .then((res) => {
        console.log('profile:', res.data)
        if (res.data.status == true) {
          let profileAuth = Object.keys(res.data.auth.permissions)
          let profile = res.data.profile
          sessionStorage.setItem('userAuth', profileAuth)
          commit(types.GET_PROFILEAUTH, profileAuth)
          commit(types.GET_PROFILE, profile)
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 更新個人資料
  actionUpdateProfile({ commit }, data) {
    axios
      .post('api/profile/update', {
        _method: 'PUT',
        name: data.name,
        password: data.password,
        password_confirmation: data.confirmPassword,
      })
      .then((res) => {
        console.log('update profile:', res.data)
        if (res.data.status == true) {
          sessionStorage.setItem('userName', res.data.profile.name)
          //    成功彈窗
          let resData = {
            code: 555,
            msg: '資料更新成功',
          }
          commit(types.SHOW_MODAL_SUCCESS, resData)
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
}

const mutations = {
  [types.GET_PROFILEAUTH](state, profileAuth) {
    state.profileAuth = profileAuth
  },
  [types.GET_PROFILE](state, profile) {
    state.profile = profile
  },
}

const getters = {
  get_profileAuth: (state) => state.profileAuth,
  get_profile: (state) => state.profile,
}
export default {
  state,
  actions,
  mutations,
  getters,
}
