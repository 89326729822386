import Global from '@/Global'
import axios from '@/axios'
import * as types from '../mutations_type'
import router from '@/router'

const state = {}

const actions = {
  actionLogin({ commit }, data) {
    let token = 'gsgsdfsd98g76s54f6wefes689489'
    sessionStorage.setItem('token', token)
    router.push('/')
    // axios
    //   .post('api/login', {
    //     email: data.email,
    //     password: data.password,
    //   })
    //   .then((res) => {
    //     console.log('登入:', res.data)
    //     if (res.data.status == true) {
    //       let token = res.data.token.access_token
    //       //   let createAt = res.data.token.created_at
    //       //   let auth = res.data.auth
    //       //   let userAuth = Object.keys(res.data.auth.permissions)
    //       sessionStorage.setItem('token', token)
    //       //   sessionStorage.setItem('createAt', createAt)
    //       //   sessionStorage.setItem('userType', auth.company.type_name)
    //       //   sessionStorage.setItem('companyID', auth.company.id)
    //       //   sessionStorage.setItem('companyName', auth.company.name)
    //       //   sessionStorage.setItem('userName', auth.name)
    //       //   sessionStorage.setItem('userMail', auth.email)
    //       //   sessionStorage.setItem(
    //       //     'role',
    //       //     Object.keys(auth.roles)
    //       //       .map((k) => auth.roles[k])
    //       //       .join(',')
    //       //   )
    //       //   sessionStorage.setItem('userAuth', userAuth)
    //       router.push('/')
    //     } else {
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     let resData = {
    //       code: error.code,
    //       msg: error.message,
    //       message: error.errors,
    //     }
    //     commit(types.SHOW_API_RES, resData)
    //   })
  },
  // 忘記密碼
  actionForgotPW({ commit }, email) {
    return new Promise((resolve, reject) => {
      axios
        .post('api/forgot_password', {
          email: email,
        })
        .then((res) => {
          console.log('忘記密碼:', res.data)
          if (res.data.status == true) {
            // 成功彈窗
            let resData = {
              code: res.data.code,
              msg: res.data.message,
            }
            commit(types.SHOW_MODAL_SUCCESS, resData)
            resolve(true)
          } else {
          }
        })
        .catch((error) => {
          console.log(error)
          let resData = {
            code: error.code,
            msg: error.message,
            message: error.errors,
          }
          commit(types.SHOW_API_RES, resData)
        })
    })
  },
  //   重設密碼
  actionResetPW({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('api/reset_password', {
          token: data.token,
          email: data.email,
          password: data.password,
          password_confirmation: data.password_confirmation,
        })
        .then((res) => {
          console.log('重設密碼:', res.data)
          if (res.data.status == true) {
            // 成功彈窗
            let resData = {
              code: res.data.code,
              msg: res.data.message,
            }
            commit(types.SHOW_MODAL_SUCCESS, resData)
            resolve(true)
          } else {
          }
        })
        .catch((error) => {
          console.log(error)
          let resData = {
            code: error.code,
            msg: error.message,
            message: error.errors,
          }
          commit(types.SHOW_API_RES, resData)
        })
    })
  },
  //   登出
  actionLogout({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('api/logout')
        .then((res) => {
          //   console.log('登出:', res.data)
          if (res.data.status == true) {
            sessionStorage.clear()
            localStorage.removeItem('getSessionStorageData')
            router.push('/login')
            resolve(true)
          } else {
          }
        })
        .catch((error) => {
          console.log(error)
          let resData = {
            code: error.code,
            msg: error.message,
            message: error.errors,
          }
          commit(types.SHOW_API_RES, resData)
        })
    })
  },
}

const mutations = {}

const getters = {}
export default {
  state,
  actions,
  mutations,
  getters,
}
