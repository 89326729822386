import axios from 'axios'

// Authorization Bearer Token
axios.defaults.headers.common['Accept-Language'] = localStorage.getItem(
  'LOCALE'
)
axios.defaults.headers.common['Authorization'] =
  'Bearer ' + sessionStorage.getItem('token')
// baseURL
axios.defaults.baseURL = 'https://alan-test.dynacloud.site/'
// https://gms-alpha.dynacloud.co/
// axios.defaults.baseURL = 'https://' + location.hostname + '/'

// raw params  =>  JSON.stringify  =>  (params, key)

const transformRequest = [
  (data = {}) => data,
  ...axios.defaults.transformRequest,
]
axios.defaults.transformRequest = transformRequest

// For logging purpose
axios.interceptors.request.use(
  (req) => {
    console.log(`interceptors: ${req.method} ${req.url}`)
    const token = sessionStorage.getItem('token')
    req.headers.Authorization = 'Bearer ' + token
    // Important: request interceptors **must** return the request.

    return req
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

export default axios
