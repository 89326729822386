<script>
// 全停用
// const $API_URL = 'https://alan-test.dynacloud.site/'
const $API_URL = 'https://allypms-stage.dynacloud.site/'
// const $API_URL = 'https://ally.pondai.at.tw/'
// const $API_URL = 'https://' + location.hostname + '/'

export default {
  $API_URL,

  //   $API_MD5_KEY,
  //   $API_AES_KEY,
  //   $API_IV,1
}
</script>
