import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
  mode: 'hash',
  //   mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
})

function configRoutes() {
  return [
    {
      // 登入
      path: '/Login',
      name: 'Login',
      component: (resolve) => require(['@/views/Auth/Login'], resolve),
    },

    {
      //   忘記密碼
      path: '/ResetPassword/:token',
      name: 'ResetPassword',
      component: (resolve) => require(['@/views/Auth/ResetPassword'], resolve),
    },
    {
      //   忘記密碼
      path: '/ResetPassword',
      name: 'ResetPassword',
      component: (resolve) => require(['@/views/Auth/ResetPassword'], resolve),
    },

    {
      path: '/',
      redirect: '/Dashboard',
      name: 'Home',
      meta: {
        requireAuth: true,
      },
      component: (resolve) => require(['@/containers/TheContainer'], resolve),
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: (resolve) => require(['@/views/Dashboard'], resolve),
        },
        {
          path: 'profile',
          name: 'Profile',
          component: (resolve) => require(['@/views/Auth/Profile'], resolve),
        },
        {
          path: 'upLoad',
          name: 'Upload',
          component: (resolve) => require(['@/views/Upload/upload'], resolve),
        },

        {
          path: '/companies',
          name: 'Companies',
          redirect: '/Companies',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '/companies',
              component: (resolve) =>
                require(['@/views/Company/company'], resolve),
            },
            {
              path: '/company/edit/:id',
              name: 'Edit Company',
              component: (resolve) =>
                require(['@/views/Company/EditCompany'], resolve),
            },
            {
              path: '/company/edit',
              name: 'Edit Company',
              component: (resolve) =>
                require(['@/views/Company/EditCompany'], resolve),
            },
            {
              path: '/company/create',
              name: 'Create Company',
              component: (resolve) =>
                require(['@/views/Company/CreateCompany'], resolve),
            },
            {
              path: '/company/view/:id',
              name: 'View Company',
              component: (resolve) =>
                require(['@/views/Company/ViewCompany'], resolve),
            },
            {
              path: '/company/view',
              name: 'View Company',
              component: (resolve) =>
                require(['@/views/Company/ViewCompany'], resolve),
            },
            // {
            //   path: '/projectCode',
            //   name: 'Project Code',
            //   component: (resolve) =>
            //     require(['@/views/ProjectCode/projectCode'], resolve),
            // },

            {
              path: '/projectCode',
              name: 'Project Code',
              redirect: '/projectCode',
              component: {
                render(c) {
                  return c('router-view')
                },
              },
              children: [
                {
                  path: '/projectCode',

                  component: (resolve) =>
                    require(['@/views/ProjectCode/projectCode'], resolve),
                },
                {
                  path: '/projectCode/edit/:id',
                  name: 'Edit Project Code',
                  component: (resolve) =>
                    require(['@/views/ProjectCode/EditProjectCode'], resolve),
                },
                {
                  path: '/projectCode/edit',
                  name: 'Edit Project Code',
                  component: (resolve) =>
                    require(['@/views/ProjectCode/EditProjectCode'], resolve),
                },

                {
                  path: '/projectCode/view/:id',
                  name: 'View Project Code',
                  component: (resolve) =>
                    require(['@/views/ProjectCode/ViewProjectCode'], resolve),
                },
                {
                  path: '/projectCode/view',
                  name: 'View Project Code',
                  component: (resolve) =>
                    require(['@/views/ProjectCode/ViewProjectCode'], resolve),
                },
              ],
            },
          ],
        },

        {
          path: '/users',
          name: 'Users',
          redirect: '/users',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '/users',
              component: (resolve) => require(['@/views/User/users'], resolve),
            },
            {
              path: '/user/edit/:id',
              name: 'Edit User',
              component: (resolve) =>
                require(['@/views/User/EditUser'], resolve),
            },
            {
              path: '/user/edit',
              name: 'Edit User',
              component: (resolve) =>
                require(['@/views/User/EditUser'], resolve),
            },
            {
              path: '/user/create',
              name: 'Create User',
              component: (resolve) =>
                require(['@/views/User/CreateUser'], resolve),
            },
            {
              path: '/user/view/:id',
              name: 'View User',
              component: (resolve) =>
                require(['@/views/User/ViewUser'], resolve),
            },
            {
              path: '/user/view',
              name: 'View User',
              component: (resolve) =>
                require(['@/views/User/ViewUser'], resolve),
            },
          ],
        },
        {
          path: '/role',
          name: 'Role',
          redirect: '/role',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '/role',
              component: (resolve) => require(['@/views/Role/role'], resolve),
            },
            {
              path: '/role/edit/:id',
              name: 'Edit Role',
              component: (resolve) =>
                require(['@/views/Role/EditRole'], resolve),
            },
            {
              path: '/role/edit',
              name: 'Edit Role',
              component: (resolve) =>
                require(['@/views/Role/EditRole'], resolve),
            },
            // {
            //   path: '/role/create',
            //   name: 'Create Role',
            //   component: (resolve) =>
            //     require(['@/views/Role/CreateRole'], resolve),
            // },
          ],
        },

        {
          path: '/userProjectCode',
          name: 'User Project Code',
          redirect: '/userProjectCode',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '/userProjectCode',
              component: (resolve) =>
                require(['@/views/UserBa/userBa'], resolve),
            },
            {
              path: '/userBa/edit/:id',
              name: 'Edit User BA',
              component: (resolve) =>
                require(['@/views/UserBa/EditUserBa'], resolve),
            },
            {
              path: '/userBa/edit',
              name: 'Edit User BA',
              component: (resolve) =>
                require(['@/views/UserBa/EditUserBa'], resolve),
            },
          ],
        },

        {
          path: '/exchangeRate',
          name: 'ExchangeRate',
          redirect: '/exchangeRate',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '/exchangeRate',
              component: (resolve) =>
                require(['@/views/ExchangeRate/exchangeRate'], resolve),
            },
            {
              path: '/exchangeRate/edit/:id',
              name: 'Edit ExchangeRate',
              component: (resolve) =>
                require(['@/views/ExchangeRate/EditExchangeRate'], resolve),
            },
            {
              path: '/exchangeRate/edit',
              name: 'Edit ExchangeRate',
              component: (resolve) =>
                require(['@/views/ExchangeRate/EditExchangeRate'], resolve),
            },
          ],
        },

        {
          path: '/bankAccount',
          name: 'Bank Account',
          redirect: '/bankAccount',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '/bankAccount',
              component: (resolve) =>
                require(['@/views/BankAccount/bankAccount'], resolve),
            },
            {
              path: '/bankAccount/edit/:id',
              name: 'Edit BankAccount',
              component: (resolve) =>
                require(['@/views/BankAccount/EditBankAccount'], resolve),
            },
            {
              path: '/bankAccount/edit',
              name: 'Edit BankAccount',
              component: (resolve) =>
                require(['@/views/BankAccount/EditBankAccount'], resolve),
            },
          ],
        },

        {
          path: '/statements',
          name: 'Statements',
          redirect: '/statements',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '/statements',
              component: (resolve) =>
                require(['@/views/Statement/statement'], resolve),
            },
            {
              path: '/statements/edit/:id',
              name: 'Edit Statement',
              component: (resolve) =>
                require(['@/views/Statement/EditStatement'], resolve),
            },
            {
              path: '/statements/edit',
              name: 'Edit Statement',
              component: (resolve) =>
                require(['@/views/Statement/EditStatement'], resolve),
            },
          ],
        },
      ],
    },
    { path: '*', redirect: '/login' },
  ]
}

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    const token = window.sessionStorage.getItem('token')
    if (to.matched.some((res) => res.meta.requireAuth)) {
      // 判斷該路由是否需要登入許可權
      if (token) {
        // 取當前的token是否存在
        next()
      } else {
        next({
          name: 'Login',
          // path: '/login',
          //   query: { redirect: to.fullPath }, // 將跳轉的路由path作為引數，登入成功後跳轉到該路由
        })
      }
    } else {
      next()
    }
  }, 500)
})
// 解決VUE項目重複點擊菜單報錯
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default router
