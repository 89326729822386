import axios from '@/axios'
import * as types from '../mutations_type'
import router from '@/router'

const state = {
  companies: '',
  companiesPage: '',
  company: '',
  companyLocation: '',
  companyContracts: '',
  companyBank: '',
  inStock: '',
  companiesAll: '',
  futureBank: '',
}

const actions = {
  // 公司列表(全)
  actionCompaniesAll({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('api/companies/all', {
          orderBy: data.orderBy,
          sortedBy: data.sortedBy,
        })
        .then((res) => {
          //   console.log('公司全列表:', res.data)
          if (res.data.status == true) {
            let companiesAll = res.data.companies
            commit(types.GET_COMPANIESALL, companiesAll)
            resolve(true)
          } else {
          }
        })
        .catch((error) => {
          console.log(error)
          let resData = {
            code: error.code,
            msg: error.message,
            message: error.errors,
          }
          commit(types.SHOW_API_RES, resData)
        })
    })
  },
  // 公司列表
  actionCompanies({ commit }, data) {
    axios
      .post(
        'api/companies',
        {
          orderBy: data.orderBy,
          sortedBy: data.sortedBy,
        },
        {
          params: {
            page: data.page,
          },
        }
      )
      .then((res) => {
        console.log('公司資料:', res.data)
        if (res.data.status == true) {
          let companies = res.data.companies
          let companiesPage = res.data.meta
          commit(types.GET_COMPANIES, companies)
          commit(types.GET_COMPANIES_PAGE, companiesPage)
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },

  // 建立公司
  actionCreateCompanies({ commit }, data) {
    axios
      .post('api/companies' + data.id, {})
      .then((res) => {
        console.log('建立公司:', res.data)
        if (res.data.status == true) {
          router.go(-1)
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }

        commit(types.SHOW_API_RES, resData)
      })
  },
  // 編輯公司
  actionEditCompanies({ commit }, data) {
    axios
      .path('api/companies/' + data.id, {})
      .then((res) => {
        console.log('編輯公司:', res.data)
        if (res.data.status == true) {
          router.go(-1)
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 檢視公司
  actionCompany({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get('api/companies/' + id)
        .then((res) => {
          console.log('檢視公司:', res.data)
          if (res.data.status == true) {
            let company = res.data.company
            let companyLocation = res.data.company.location
            let companyContracts = res.data.company.current_contracts
            let companyBank = res.data.company.payment_bank_account
            let futureBank = res.data.company.future_payment_bank_account
            commit(types.GET_COMPANY, company)
            commit(types.GET_COMPANYCONTRACTS, companyContracts)
            commit(types.GET_COMPANYLOCATION, companyLocation)
            commit(types.GET_COMPANYBANK, companyBank)
            commit(types.GET_FUTUREBANK, futureBank)

            resolve(true)
          } else {
          }
        })
        .catch((error) => {
          console.log(error)
          let resData = {
            code: error.code,
            msg: error.message,
            message: error.errors,
          }
          commit(types.SHOW_API_RES, resData)
        })
    })
  },
}

const mutations = {
  [types.GET_COMPANIESALL](state, companiesAll) {
    state.companiesAll = companiesAll
  },
  [types.GET_COMPANIES](state, companies) {
    state.companies = companies
  },
  [types.GET_COMPANIES_PAGE](state, companiesPage) {
    state.companiesPage = companiesPage
  },
  [types.GET_COMPANY](state, company) {
    state.company = company
  },
  [types.GET_COMPANYLOCATION](state, companyLocation) {
    state.companyLocation = companyLocation
  },
  [types.GET_COMPANYCONTRACTS](state, companyContracts) {
    state.companyContracts = companyContracts
  },
  [types.GET_COMPANYBANK](state, companyBank) {
    state.companyBank = companyBank
  },
  [types.GET_FUTUREBANK](state, futureBank) {
    state.futureBank = futureBank
  },

  [types.GET_INSTOCK](state, inStock) {
    state.inStock = inStock
  },
}

const getters = {
  get_companiesAll: (state) => state.companiesAll,
  get_companies: (state) => state.companies,
  get_companiesPage: (state) => state.companiesPage,
  get_company: (state) => state.company,
  get_companyLocation: (state) => state.companyLocation,
  get_companyContracts: (state) => state.companyContracts,
  get_companyBank: (state) => state.companyBank,
  get_futureBank: (state) => state.futureBank,
  get_inStock: (state) => state.inStock,
}
export default {
  state,
  actions,
  mutations,
  getters,
}
