import axios from '@/axios'
import * as types from '../mutations_type'
import router from '@/router'

const state = {}
const actions = {
  // BA列表
  actionbaList({ commit }, data) {
    axios
      .post(
        'api/billing_accounts',
        {
          orderBy: data.orderBy,
          sortedBy: data.sortedBy,
        },
        {
          params: {
            page: data.page,
          },
        }
      )
      .then((res) => {
        console.log('BA資料:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // BA詳情
  actionBillingAccount({ commit }, data) {
    axios
      .post('api/billing_accounts/' + data.id)
      .then((res) => {
        console.log('BA詳情:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 新增BA
  actionAddBa({ commit }, data) {
    axios
      .post('api/billing_accounts/store', {
        orderBy: data.orderBy,
        sortedBy: data.sortedBy,
      })
      .then((res) => {
        console.log('新增BA:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 編輯使用者
  actionEditBa({ commit }, data) {
    axios
      .path('api/billing_accounts/' + data.id, {
        orderBy: data.orderBy,
        sortedBy: data.sortedBy,
      })
      .then((res) => {
        console.log('編輯BA:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 刪除使用者
  actionImportBa({ commit }, data) {
    axios
      .post('api/billing_account/import_csv', {})
      .then((res) => {
        console.log('上傳月結單:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
}
const mutations = {}
const getters = {}
export default {
  state,
  actions,
  mutations,
  getters,
}
