import axios from '@/axios'
import * as types from '../mutations_type'
import router from '@/router'

const state = {}
const actions = {
  // 使用者列表
  actionUserList({ commit }, data) {
    axios
      .post(
        'api/users',
        {
          orderBy: data.orderBy,
          sortedBy: data.sortedBy,
        },
        {
          params: {
            page: data.page,
          },
        }
      )
      .then((res) => {
        console.log('使用者資料:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 使用者詳情
  actionUser({ commit }, data) {
    axios
      .post('api/users/' + data.id)
      .then((res) => {
        console.log('使用者詳情:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 新增使用者
  actionAddUser({ commit }, data) {
    axios
      .post('api/users/store', {
        orderBy: data.orderBy,
        sortedBy: data.sortedBy,
      })
      .then((res) => {
        console.log('新增使用者:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 編輯使用者
  actionEditUser({ commit }, data) {
    axios
      .path('api/users/' + data.id, {
        orderBy: data.orderBy,
        sortedBy: data.sortedBy,
      })
      .then((res) => {
        console.log('編輯使用者:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 刪除使用者
  actionDelUser({ commit }, data) {
    axios
      .delete('api/users/' + data.id)
      .then((res) => {
        console.log('刪除使用者:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
}
const mutations = {}
const getters = {}
export default {
  state,
  actions,
  mutations,
  getters,
}
