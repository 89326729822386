import axios from '@/axios'
import * as types from '../mutations_type'
import router from '@/router'

const state = {}
const actions = {
  // 單據列表
  actionStatementList({ commit }, data) {
    axios
      .post(
        'api/statements',
        {
          orderBy: data.orderBy,
          sortedBy: data.sortedBy,
        },
        {
          params: {
            page: data.page,
          },
        }
      )
      .then((res) => {
        console.log('單據列表:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 關帳
  actionCloseStatement({ commit }, data) {
    axios
      .post('api/statement/closing')
      .then((res) => {
        console.log('關帳:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 產生單據
  actionGenerateStatement({ commit }, data) {
    axios
      .post('api/statement/generate')
      .then((res) => {
        console.log('產生單據:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
  // 下載單據
  actionDownload({ commit }, data) {
    axios
      .post('api/statement/generate')
      .then((res) => {
        console.log('下載單據:', res.data)
        if (res.data.status == true) {
        } else {
        }
      })
      .catch((error) => {
        console.log(error)
        let resData = {
          code: error.code,
          msg: error.message,
          message: error.errors,
        }
        commit(types.SHOW_API_RES, resData)
      })
  },
}
const mutations = {}
const getters = {}
export default {
  state,
  actions,
  mutations,
  getters,
}
